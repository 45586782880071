<script>
export default {
  name: 'ScreenHeightMixin',
  data() {
    return {
      screenContainerHeight: '500px',
    };
  },
  mounted() {
    window.addEventListener('resize', this.resizeHandler);
    this.resizeHandler();
  },
  unmounted() {
    window.removeEventListener('resize', this.resizeHandler);
  },
  methods: {
    resizeHandler() {
      if (this.$refs.screenContainer != null) {
        this.screenContainerHeight = `${(window.innerHeight - this.$refs.screenContainer.offsetTop - 10)}px`;
      } else {
        setTimeout(this.resizeHandler, 100);
      }
    },
  },
};

</script>
