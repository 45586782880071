<template>
  <div>
    <DeviceListComponent/>
  </div>
</template>

<script>
import DeviceListComponent from '@/components/DeviceListComponent.vue';

export default {
  name: 'DevicesView',
  components: {
    DeviceListComponent,
  },
  props: [],
  mounted() {

  },
};
</script>
