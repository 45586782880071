<template>
  <div class="sizeStats" v-if="tenant != null && tenant.sizes != null">
    <div class="sizeBar"><div class="sizeBarValue" :style="{ width: calcSizeBarWidth+'%'} "/></div><br/>
    {{(tenant.sizes.completeSize / 1024 / 1024).toFixed(2) }} MB / {{tenant.maxSizeInMB }} MB ({{calcSizeBarWidth.toFixed(2)}} %)
  </div>
</template>
<script>
/* eslint-disable no-console,no-alert,no-underscore-dangle */

import ServiceHelper from '@/helpers/ServiceHelper';

export default {
  name: 'SpaceIndicatorComponent',
  components: {
  },
  data() {
    return {
      tenant: null,
    };
  },
  mounted() {
    this.loadTenant();
  },
  methods: {
    async refresh() {
      await this.loadTenant();
    },
    async loadTenant() {
      try {
        this.tenant = (await ServiceHelper.loadDataFromBackend('tenant')).payload.tenant;
      } catch (e) {
        console.error(e);
      }
    },
  },
  computed: {
    calcSizeBarWidth() {
      const s = ((this.tenant.sizes.completeSize / 1024 / 1024) / this.tenant.maxSizeInMB) * 100;
      return s;
    },
    server() {
      return localStorage.getItem('col_server');
    },
  },
};
</script>
<style scoped>
.sizeStats {
  text-align: left;
  font-size: 12px;
}

.sizeBar {
  display: inline-block;
  background: var(--color_gray);
  width: 200px;
  height: 12px;
  position: relative;
  overflow: hidden;
}
.sizeBar .sizeBarValue {
  position: absolute;
  height: 100%;
  background: var(--color_orange);
  width: 0%;
}

.sizeStats {
  margin-bottom: 16px;
}
</style>
