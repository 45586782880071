<template>
  <div class="deviceList">
    <div class="page-title">{{ $t('pageTitle.devices') }}
      <span v-if="tenant != null">(max. {{tenant.deviceCount}})</span>
      <span v-if="tenant != null && tenant.subscriptionType == 'EXPIRED'">&nbsp;<i class="fas fa-exclamation-triangle"/></span>
    </div>
    <div class="actionBar">
      <div class="actionButton bgBrown refresh" @click="loadDevices"><i class="fas fa-sync"></i>
        <div class='actionButtonTitle'>{{ $t('general.refresh') }}</div>
      </div>
    </div>
    <div class="topNav">
      <router-link to="/settings"><div class='navButton'><i class="fas fa-arrow-left"/> {{ $t('general.back') }}</div></router-link>
    </div>
    <div class="screen-container" ref="screenContainer" :style='{ height: screenContainerHeight }'>
      <EmptyListAnimationComponent v-if="devices != null && devices.length == 0"/>
      <div class="listWrapper">
        <SpaceIndicatorComponent/>
        <div class="listItemWrapper" :class="{'hasLogsOpen': deviceLog != null && deviceLog.device.uuid == entry.uuid}" v-for="entry in devices" :key="entry._id">
          <div class="listItem device">
            <div v-if='["IPHONE", "ANDROID_PHONE"].includes(entry.type)' class="icon"><i class="fas fa-mobile-alt"/></div>
            <div v-else-if='["IPAD", "ANDROID_TABLET"].includes(entry.type)' class="icon"><i class="fas fa-tablet-alt"/></div>
            <div v-else class="icon"><i class="fas fa-desktop"/></div>
            {{ entry.name == null ? '' : (entry.name.substring(0,20) + (entry.name.length > 20 ? '...' : '')) }}
            ({{entry.uuid}}), {{entry.user.firstname}} {{entry.user.lastname}}, {{new Date(entry.modified).toLocaleString()}}</div>
          <div class="listActionWrapper">
            <span class="listAction deviceAction" @click="deleteDevice(entry._id)"><i class="fas fa-trash"/></span>
            <span v-if="entry.hasLogs" class="listAction deviceAction" @click="openLog(entry)"><i class="far fa-file-alt"/></span>
          </div>
          <div v-if="deviceLog != null && deviceLog.device.uuid == entry.uuid" class="deviceLog">
            <pre class="logContent" v-html="deviceLog.content"></pre>
          </div>
        </div>
      </div>
    </div>
    <ConfirmModal ref="confirmDeleteModal" :title="$t('modal.titleDelete')" :text="$t('modal.textDelete')"/>
  </div>
</template>

<script>
/* eslint-disable no-console,no-alert,no-underscore-dangle */

import ServiceHelper from '@/helpers/ServiceHelper';
import CryptoHelper from '@/helpers/CryptoHelper';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import ScreenHeightMixin from '@/mixins/ScreenHeightMixin.vue';
import EmptyListAnimationComponent from '@/components/EmptyListAnimationComponent.vue';
import SpaceIndicatorComponent from '@/components/SpaceIndicatorComponent.vue';

export default {
  name: 'DeviceListComponent',
  props: [],
  mixins: [ScreenHeightMixin],
  components: {
    ConfirmModal,
    EmptyListAnimationComponent,
    SpaceIndicatorComponent,
  },
  data() {
    return {
      devices: [],
      tenant: null,
      deviceLog: null,
    };
  },
  watch: {
  },
  mounted() {
    this.loadDevices();
  },
  methods: {
    async loadDevices() {
      this.devices = [];
      try {
        const data = await ServiceHelper.loadDataFromBackend('devices');
        this.tenant = data.payload.tenant;
        if (this.tenant.tenantName != null && this.tenant.tenantName.length > 0) {
          this.tenant.tenantName = CryptoHelper.decrypt(this.tenant.tenantName);
        }
        data.payload.devices.forEach((entry) => {
          try {
            const e = entry;
            if (entry.name != null && entry.name.length > 0) e.name = CryptoHelper.decrypt(e.name);
            if (entry.user.firstname != null && entry.user.firstname.length > 0) e.user.firstname = CryptoHelper.decrypt(e.user.firstname);
            if (entry.user.lastname != null && entry.user.lastname.length > 0) e.user.lastname = CryptoHelper.decrypt(e.user.lastname);
          } catch (e) {
            console.error(e);
          }
        });
        this.devices = data.payload.devices;
      } catch (error) {
        console.error(error);
      }
      return null;
    },
    async deleteDevice(deviceId) {
      if (await this.$refs.confirmDeleteModal.show()) {
        try {
          const url = `device/${deviceId}`;
          await ServiceHelper.deleteDataFromBackend(url);
          this.loadDevices();
        } catch (error) {
          console.error(error);
        }
      }
      return null;
    },
    async openLog(device) {
      if (this.deviceLog != null && this.deviceLog.device.uuid === device.uuid) {
        this.deviceLog = null;
        return;
      }
      const data = await ServiceHelper.loadDataFromBackend(`device/log/${device.uuid}`);
      const { content } = data.payload;
      if (content != null) {
        console.log(content);
        this.deviceLog = {
          content: content.replace(/\\n/g, '\n').replace(/\\t/g, '\t'),
          device,
        };
      }
    },
  },
};
</script>

<style scoped>
@import url('../styles/list.module.css');

.listWrapper .listItemWrapper:nth-child(odd) {
  background-color: var(--color_list_1);
}
.listWrapper .listItemWrapper:nth-child(even) {
  background-color: var(--color_list_2);
}

.deviceLog {
  padding: 8px;
  border: 1px solid gray;
  font-size: 12px;
  line-height: 14px;
  width: 97%;
  margin-left: 2%;
}

.logContent {
  font-family: 'Times New Roman', Times, serif;
  text-align: left;
  padding: 8px 0px;
  max-height: 400px;
  overflow: auto;
}

.listWrapper .listItemWrapper.hasLogsOpen {
  min-height: 40px;
  height: auto;
}

</style>
